import React from 'react';

import TableRow from '../TableRow';
import DeleteModal from '../DeleteModal';
import { orderListElems, range } from '../../utils';

import styled from 'styled-components';

import { DataContext } from '../DataProvider';

const HEADERS = [
  { title: '', width: 'var(--play-icon-td-width)' },
  { title: 'Artist', width: 'var(--artist-td-width)' },
  { title: 'Name', width: 'var(--songname-td-width)' },
  { title: 'Time', width: 'var(--length-td-width)' },
  { title: 'Size', width: 'var(--size-td-width)' },
  { title: 'Plays', width: 'var(--plays-td-width)' },
  { title: 'Last Played', width: 'var(--date-td-width)' },
  { title: 'Date Added', width: 'var(--date-td-width)' },
  { title: 'Stars', width: 'var(--stars-td-width)' },
  // // { title: '', width: 'var(--final-td-width)' },
  // { title: '', width: 'min-width: calc(100vw - 1184px)' },
];

const reverseSort = false;

function Table() {
  const { rows, setRows, selectedSong, isSearching, isEditing } =
    React.useContext(DataContext);
  const [lastSelectedColumn, setLastSelectedColumn] = React.useState(1);
  const [isSortReversed, setIsSortReversed] = React.useState(false);

  function handleColumnSelect(index) {
    const sortedOnSameColumn = lastSelectedColumn === index;
    const isReverseByDefault = false;
    const reverseOption = sortedOnSameColumn
      ? !isSortReversed
      : isReverseByDefault;
    setRows((oldRows) => {
      const newRows = [...rows];
      const orderedData = newRows.sort((a, b) =>
        orderListElems(a, b, reverseOption, index - 1)
      );
      return orderedData;
    });
    const newIndex = index === 100 ? 100 : index - 1;
    setLastSelectedColumn(index);
    if (!sortedOnSameColumn) {
      setIsSortReversed(false);
    } else {
      setIsSortReversed((oldValue) => !oldValue);
    }
  }

  function deleteSongFromUI() {
    setRows((oldRows) => {
      const newData = oldRows.filter((row) => row.KEY !== selectedSong.KEY);
      return newData;
    });
  }

  const numRowsToDisplay = (rows && Math.min(rows.length, 300)) || 300;

  React.useEffect(() => {
    function handleBackspace(event) {
      if (
        (event.code === 'Backspace' || event.code === 'Delete') &&
        !isSearching &&
        !isEditing
      ) {
        if (Object.keys(selectedSong).length !== 0 && selectedSong) {
          setIsModalOpen(true);
        }
      }
    }
    window.addEventListener('keydown', handleBackspace);
    return () => {
      window.removeEventListener('keydown', handleBackspace);
    };
  }, [selectedSong, isSearching, isEditing, rows]);
  const fillerItem = {
    ARTIST: 'Loading...',
    SONGNAME: 'Loading...',
    TIME: '?',
    FILESIZE: 1234,
    PLAYCOUNT: 0,
    KEY: Math.random().toString(),
  };

  return (
    <>
      <Wrapper>
        <TableHeader>
          <TableHeaderRowWrapper key="headerRow">
            {HEADERS.map((item, index) => (
              <TableHeaderElementWrapper
                width={item.width}
                key={item.title}
                onClick={() => handleColumnSelect(index)}
              >
                <TableHeaderCursorWrapper>
                  {item.title}
                </TableHeaderCursorWrapper>
              </TableHeaderElementWrapper>
            ))}
            <TableHeaderElementWrapper>
              <FinalTHContent></FinalTHContent>
            </TableHeaderElementWrapper>
          </TableHeaderRowWrapper>
        </TableHeader>
        <TBodyWrapper>
          {rows &&
            rows
              .slice(0, numRowsToDisplay)
              .map((item) => <TableRow key={item.KEY} item={item} />)}
          {!rows &&
            range(100).map((item) => (
              <TableRow key={Math.random()} item={fillerItem} />
            ))}
        </TBodyWrapper>
      </Wrapper>
    </>
  );
}

const TBodyWrapper = styled.tbody`
  translate: 0 2px;
`;

const FinalTHContent = styled.div`
  /* backgroundx  -color: red; */
  min-width: calc(100vw - 1184px);
`;

const TableHeader = styled.thead``;

const TableHeaderCursorWrapper = styled.span`
  cursor: pointer;
`;

const TableHeaderRowWrapper = styled.tr`
  position: fixed;
  top: 40.5px;
  z-index: 1000;
`;

const TableHeaderElementWrapper = styled.th`
  border-right: var(--songs-table-header-border);
  border-bottom: var(--songs-table-header-border);
  padding-left: 3px;
  font-weight: 900;
  background-color: var(--songs-table-header-background-color);
  text-align: left;
  z-index: 1;
  width: ${(p) => p.width};
`;

const Wrapper = styled.table`
  position: relative;
  display: inline;
  border-collapse: separate;
  /* border-collapse: collapse; */
  border-spacing: 0;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  /* width: 100%; */
  width: 1000px;
`;

export default React.memo(Table);
