import styled from 'styled-components';

export const Base = styled.td`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PlayIconTD = styled.td`
  width: var(--play-icon-td-width);
  /* max-width: 3px; */
  padding: 0px !important;
  line-height: 0px;
`;

export const SongNameTD = styled(Base)`
  width: var(--songname-td-width);
  max-width: var(--songname-td-width);
`;

export const ArtistTD = styled(Base)`
  width: var(--artist-td-width);
  max-width: var(--artist-td-width);
`;

export const SizeTD = styled.td`
  width: var(--size-td-width);
`;

export const PlaysTD = styled.td`
  width: var(--plays-td-width);
`;
export const LengthTD = styled.td`
  width: var(--length-td-width);
`;

// export const StarsTD = styled.td`
//   width: var(--stars-td-width);
// `;

export const LastPlayedTD = styled(Base)`
  width: var(--date-td-width);
  max-width: var(--date-td-width);
`;

export const DateAddedTD = styled(Base)`
  width: var(--date-td-width);
  max-width: var(--date-td-width);
`;

export const InputField = styled.input`
  padding: 0px;
  margin: 0px;
  border: 0px;
  outline: none;
  color: var(--input-field-default-font-color);
  max-width: ${(p) => p.maxWidth};

  &:focus {
    outline: none;
    border: none;
  }
`;
