.button {
  cursor: pointer;
}

/* .songInfo {
  width: 100%;
  font-size: 10px;
  font-weight: 400;
  position: sticky;
  top: 0px;
  z-index: 10;
  /* position: fixed;
  top: 0;
  left: 0;
} */

.artist {
  transform: translate(0px, -3.5px);
}

.playButton {
  width: 22px;
  height: 25px;
}

.backButton {
  width: 39px;
  height: 25px;
  margin-left: 38px;
}

.forwardButton {
  width: 39px;
  height: 25px;
}

.shuffleDefault {
  margin-top: 4px;
  width: 20px;
  height: 14px;
}

.loopButton {
  margin-top: 2px;
  width: 26px;
  height: 19px;
}

.loopOnButton {
  margin-top: 2px;
  width: 26px;
  height: 16px;
}

.topRow {
  border: var(--audio-player-border);
}

.outerLeft {
  /* border-right: var(--audio-player-border); */
  width: 12.5%;
  background-image: linear-gradient(
    var(--player-gradient-top),
    var(--player-gradient-bottom)
  );
}

.volumeCell {
  border-right: var(--audio-player-border);
  background-image: linear-gradient(
    var(--player-gradient-top),
    var(--player-gradient-bottom)
  );
  width: 17.5%;
}

.outerRight {
  border-left: var(--audio-player-border);
  width: 30%;
  background-image: linear-gradient(
    var(--player-gradient-top),
    var(--player-gradient-bottom)
  );
}
/* 
.outerCell {
  width: 30%;
  background-image: linear-gradient(
    var(--player-gradient-top),
    var(--player-gradient-bottom)
  );
} */

.songTimer {
  width: 30px;
  background-color: var(--player-inner-cell-background);
  font-size: 10px;
  font-weight: 400;
  position: relative;
}

.songName {
  font-size: 13px;
  margin-top: 6px;
  font-weight: 500;
  color: black;
}

.middleCell {
  width: 100%;
  background-color: var(--player-inner-cell-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.timeContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: small;
}

.iconUpperLeft {
  padding-left: 5px;
  position: absolute;
  top: 0;
  font-size: 11px;
}

.timeLowerLeft {
  padding-left: 5px;
  position: absolute;
  bottom: 0;
}

.iconUpperRight {
  position: absolute;
  top: 0;
  font-size: 11px;
}

.timeLowerRight {
  position: absolute;
  bottom: 0;
}

.playerUI {
  width: 100%;
  background-color: var(--player-inner-cell-background);
  position: relative;
  margin-top: -8px;
  text-align: center;
  align-items: center;
}

.infoAndTimes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: -10px;
}

/* #007db5 */

.timeSpan {
  font-family: 'Lato', sans-serif;
}

.playbackSpan {
  color: inherit;
}

.durationSpan {
  float: right;
}

.invisiblePlaceholder {
  visibility: hidden;
}

.invisibleImage {
  visibility: hidden;
  display: none;
}

.relativePositionClass {
  position: relative;
}

.absolutePositionClass {
  position: absolute;
  top: 0;
  left: 0;
}

.slider {
  --handle-size: 16px;
  --handle-height: 10px;
  --handle-width: 3px;
  /* height: 10px;
    width: 3px; */
  /* --handle-size: 12px; */
  --handle-radius: 10px;
  --track-size: 5px;
  --bg: white;
  /* --track-color: #888; */
  --track-color: hsla(0, 0%, 33%, 0.192);
  /* --handle-color: #f4f4f4; */
  --handle-color: #222;
  --track-active-color: #555;
  --handle-active-color: hsl(250, 100%, 36%);

  display: block;
  width: 100%;
  /* background: transparent; */
  /* background: linear-gradient(to right, var(--track-active-color) 0%, var(--track-active-color) 50%, var(--track-color) 50%); */
  background: linear-gradient(
    to right,
    #555 0%,
    #555 50%,
    hsla(0, 0%, 33%, 0.192) 50%
  );
  appearance: none;
  outline-offset: 6px;
  cursor: pointer;
  margin-top: 10px;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  height: var(--handle-height);
  width: var(--handle-width);
  border-radius: var(--handle-radius);
  background: var(--handle-color);
  cursor: grab;
  transform: translateY(calc(-50% + var(--track-size) / 2));
  outline: 1px solid var(--bg);
}

.slider::-moz-range-thumb {
  background: var(--handle-color);
  border: 2px solid var(--bg);
  border-radius: var(--handle-radius);
  height: var(--handle-size);
  width: var(--handle-size);
}

.slider:active::-webkit-slider-thumb,
.slider:active::-moz-range-thumb {
  cursor: grabbing;
  background: var(--handle-active-color);
}

.slider::-webkit-slider-runnable-track {
  width: calc(100% - var(--handle-size));
  height: var(--track-size);
  background: var(--track-color);
  border-radius: var(--track-size) / 2;
  /* margin: calc(var(--handle-size) / 2) 0; */
}

.slider::-moz-range-track {
  background: var(--track-color);
}

.slider:active::-webkit-slider-runnable-track,
.slider:active::-moz-range-track {
  background: var(--track-active-color);
}

.slider::before {
  -webkit-appearance: none;
  position: absolute;
  content: '';
  top: 8px;
  left: 0;
  width: var(--seek-before-width);
  height: 3px;
  background-color: rgb(229, 255, 0);
  background: rgb(229, 255, 0);
  color: yellow;
  cursor: pointer;
}
