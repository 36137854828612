import React from 'react';
import * as Slider from '@radix-ui/react-slider';
import './styles.css';
import '@radix-ui/colors/black-alpha.css';
import '@radix-ui/colors/violet.css';

const SliderDemo = ({ onValueChange, defaultValue }) => (
  <form>
    <Slider.Root
      className="SliderRoot"
      defaultValue={[defaultValue]}
      max={100}
      step={1}
      onValueChange={onValueChange}
    >
      <Slider.Track className="SliderTrack">
        <Slider.Range className="SliderRange" />
      </Slider.Track>
      <Slider.Thumb className="SliderThumb" aria-label="Volume" />
    </Slider.Root>
  </form>
);

export default SliderDemo;
