import React from 'react';
import * as style from './SearchBar.module.css';
import magnifyingGlass from '../../assets/magnifying-glass.png';

import { DataContext } from '../DataProvider';

// const searchBarStyle = {
//   ...style.searchBar,
//   backgroundImage: `url(${magnifyingGlass})`
// };

function SearchBar() {
  const [value, setValue] = React.useState('');
  const { handleSetSearchQuery, setIsSearching } =
    React.useContext(DataContext);

  function handleSearch(event) {
    setValue(event.target.value);
    handleSetSearchQuery(event.target.value);
  }

  return (
    <input
      className={style.searchBar}
      type="text"
      value={value}
      onChange={(e) => handleSearch(e)}
      onFocus={setIsSearching}
      onBlur={setIsSearching}
    >
      {}
    </input>
  );
}

export default SearchBar;
