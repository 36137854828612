import React from 'react';
import useIndexFetcher from '../../hooks/use-index-fetcher';
import useToggle from '../../hooks/use-toggle';
import useSettableToggle from '../../hooks/use-settable-toggle';
import { orderListElems } from '../../utils';

import { UserContext } from '../UserProvider';

export const DataContext = React.createContext();

function DataProvider({ children }) {
  const [selectedSong, setSelectedSong] = React.useState({});
  const [playingSong, setPlayingSong] = React.useState({});
  const [rows, setRows] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [isSearching, setIsSearching] = useToggle(false);
  const [isEditing, setIsEditing] = useToggle(false);
  const [isAudioPlaying, setIsAudioPlaying] = useSettableToggle(false);
  const [selectedColumn, setSelectedColumn] = React.useState(0);
  const [shortList, setShortList] = React.useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = React.useState('songs');
  const prevSelectedSong = React.useRef();
  const [isLeftBarFocused, setIsLeftBarFocused] = React.useState(true);

  const { token } = React.useContext(UserContext);
  const data = useIndexFetcher(token);

  // Populate table on loading index from API endpoint
  React.useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, [data]);

  function addToShortList(item, isPlayNext) {
    if (isPlayNext) {
      setShortList((oldValues) => {
        return [...oldValues, item];
      });
    } else {
      setShortList((oldValues) => {
        return [item, ...oldValues];
      });
    }
  }

  function removeFromShortList() {
    setShortList((oldValues) => {
      return oldValues.slice(0, -1);
    });
  }

  function checkSearchTermMatch(item, term) {
    let result = false;
    const fields = [item.ARTIST, item.SONGNAME];
    let all_blank = true;
    fields.forEach((field) => {
      if (!field) {
        // do nothing
      } else {
        all_blank = false;
        result |= field.toLowerCase().includes(term.toLowerCase());
      }
    });
    return result && !all_blank;
  }

  function getFilteredData(searchQuery, data, column = selectedColumn) {
    var filteredData = data;
    const searchTerms = searchQuery.split(/\s+/);
    for (const term of searchTerms) {
      filteredData = filteredData.filter((item) =>
        checkSearchTermMatch(item, term)
      );
    }
    filteredData.sort((a, b) => orderListElems(a, b, false, column));
    return filteredData;
  }

  React.useEffect(() => {
    document.title = 'S3Tunes 1.0.2';
    if (playingSong && playingSong.ARTIST) {
      document.title = `${playingSong.ARTIST} - ${playingSong.SONGNAME}`;
    }
  }, [playingSong]);

  function handleSetSearchQuery(query) {
    setSearchQuery(query);
    if (data) {
      console.log(`Query: ${query}`);
      const newRows = getFilteredData(query, data);
      setRows(newRows);
    }
  }

  function handleShuffle() {
    if (data) {
      setRows((oldRows) => {
        const newRows = [...oldRows];

        if (selectedColumn === 100) {
          newRows.forEach((row) => {
            row.RANDOM = Math.random();
          });
        }
        return getFilteredData(searchQuery, data, 100);
      });
      setSelectedColumn(100);
    }
  }

  function handleSelectSong(item) {
    setSelectedSong(item);
    prevSelectedSong.current = item;
  }

  function handleSelectPlaylist(playlist) {
    setSelectedPlaylist(playlist);
  }

  const value = {
    selectedSong,
    rows,
    setRows,
    playingSong,
    setPlayingSong,
    prevSelectedSong,
    isSearching,
    setIsSearching,
    isEditing,
    setIsEditing,
    isAudioPlaying,
    setIsAudioPlaying,
    selectedColumn,
    setSelectedColumn,
    handleSetSearchQuery,
    handleShuffle,
    handleSelectSong,
    shortList,
    addToShortList,
    removeFromShortList,
    selectedPlaylist,
    handleSelectPlaylist,
    isLeftBarFocused,
    setIsLeftBarFocused,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

export default DataProvider;
