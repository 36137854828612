import React from 'react';

function SongDate({ date }) {
  const getFormattedDate = (date) => {
    if (!date) {
      return '?';
    }
    date = new Date(date);
    if (process.env.NODE_ENV !== 'development') {
      date.setHours(date.getHours() - 4);
      const jan = new Date(date.getFullYear(), 0, 1);
      const jul = new Date(date.getFullYear(), 6, 1);
      const stdTimezoneOffset = Math.max(
        jan.getTimezoneOffset(),
        jul.getTimezoneOffset()
      );
      const isDST = date.getTimezoneOffset() < stdTimezoneOffset;
      date.setHours(date.getHours() - (isDST ? 4 : 5));
    }
    var day = date.getDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var hour = date.getHours();
    var minute = date.getMinutes();
    if (minute < 10) {
      minute = '0' + minute;
    }
    // check if hour is AM or PM factoring in midnight
    var ampm = hour >= 12 ? 'PM' : 'AM';
    // convert hour to 12 hour format
    hour = hour % 12;
    // if hour is 0, set it to 12
    hour = hour ? hour : 12;
    return `${month}/${day}/${year} ${hour}:${minute} ${ampm}`;
  };

  return <>{getFormattedDate(date)}</>;
}

export default React.memo(SongDate);
