.selectedRow {
  background-color: #2884fc !important;
  color: #ffffff !important;
}

.songTableRow {
  cursor: pointer;
}

.starsTD {
  width: 20px;
}

.invisiblePlaceholder {
  visibility: hidden;
}

.invisibleImage {
  visibility: hidden;
  display: none;
}

.speakerIcon {
  height: 15px;
  width: 15px;
  margin-left: -16px;
}

.speakerOnUnselectedIcon {
  height: 15px;
  width: 15px;
  margin-left: -16px;
  margin-top: -1px;
}

.relativePositionClass {
  position: relative;
}

.absolutePositionClass {
  position: absolute;
  top: 0;
  left: 0;
}
