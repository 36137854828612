import React from 'react';

import SongSize from '../SongSize';
import SongDate from '../SongDate';

import {
  ArtistTD,
  SongNameTD,
  LengthTD,
  SizeTD,
  PlaysTD,
  LastPlayedTD,
  DateAddedTD,
} from './TableRowStyles';

function ReadOnlyTableRow({ item, handleFieldClick }) {
  return (
    <>
      <ArtistTD
        key="artist"
        onClick={() => {
          handleFieldClick('ARTIST');
        }}
      >
        {item.ARTIST}
      </ArtistTD>
      <SongNameTD
        key="songname"
        onClick={() => {
          handleFieldClick('SONGNAME');
        }}
      >
        {item.SONGNAME}
      </SongNameTD>
      <LengthTD
        key="length"
        onClick={() => {
          handleFieldClick('LENGTH');
        }}
      >
        {item.LENGTH}
      </LengthTD>

      <SizeTD key="size">
        <SongSize bytes={item.FILESIZE} />
      </SizeTD>
      <PlaysTD
        key="plays"
        onClick={() => {
          handleFieldClick('PLAYCOUNT');
        }}
      >
        {item.PLAYCOUNT}
      </PlaysTD>

      <LastPlayedTD key="lastplayed">
        {<SongDate date={item.LAST_PLAYED} />}
      </LastPlayedTD>
      <DateAddedTD key="dateadded">
        {<SongDate date={item.DATE_ADDED} />}
      </DateAddedTD>
    </>
  );
}

export default React.memo(ReadOnlyTableRow);
