import React from 'react';

import { DataContext } from '../DataProvider';
import { UserContext } from '../UserProvider';
import useToggle from '../../hooks/use-toggle';

import { editSong } from '../../utils';
import SeekerSlider from '../SeekerSlider';

import styled from 'styled-components';

const SONG_ENDPOINT = process.env.REACT_APP_SONG_URL;

function AudioPlayer() {
  const [loop, toggleLoop] = useToggle(false);
  const [autoplay, toggleAutoplay] = useToggle(true);

  const { token } = React.useContext(UserContext);

  const {
    playingSong,
    rows,
    setRows,
    setPlayingSong,
    selectedSong,
    handleSelectSong,
    isSearching,
    isEditing,
    setIsAudioPlaying,
    shortList,
    removeFromShortList,
  } = React.useContext(DataContext);
  const [src, setSrc] = React.useState('');

  const audioRef = React.useRef(null);

  React.useEffect(() => {
    async function fetchUrl(song) {
      if (Object.keys(song).length === 0) return;
      const apiUrl = `${SONG_ENDPOINT}${song.KEY}`;
      const urlResponse = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY,
          Authorization: token,
        },
      });
      const urlJson = await urlResponse.json();
      const presignedUrl = urlJson.presigned_url;
      setSrc(presignedUrl);
      window.setTimeout(() => {
        const newAudio = audioRef;
        if (
          !presignedUrl.startsWith('https://s3tunes.s3.amazonaws.com/undefined')
        ) {
          newAudio.current.play();
        }
      }, 0);
      return presignedUrl;
    }
    fetchUrl(playingSong);
  }, [playingSong]);

  React.useEffect(() => {
    function handleSpacebar(event) {
      if (event.code === 'Space' && !isSearching && !isEditing) {
        event.preventDefault();
        const audio = audioRef.current;
        if (Object.keys(playingSong).length !== 0 && audio) {
          if (audio.paused) {
            audio.play();
          } else {
            audio.pause();
          }
          setIsAudioPlaying();
        } else if (Object.keys(selectedSong).length !== 0 && selectedSong) {
          setPlayingSong(selectedSong);
        }
      }
    }
    window.addEventListener('keydown', handleSpacebar);
    return () => {
      window.removeEventListener('keydown', handleSpacebar);
    };
  }, [isSearching, isEditing, selectedSong, playingSong]);

  React.useEffect(() => {
    function handleArrow(event) {
      if (
        (event.code === 'ArrowRight' || event.code === 'ArrowLeft') &&
        !isSearching &&
        !isEditing
      ) {
        event.preventDefault();
        const audio = audioRef.current;
        if (Object.keys(playingSong).length !== 0 && audio) {
          const forward = event.code === 'ArrowRight';
          playNextSong(forward, rows.length, shortList);
        }
      }
    }
    window.addEventListener('keydown', handleArrow);
    return () => {
      window.removeEventListener('keydown', handleArrow);
    };
  }, [isSearching, isEditing, playingSong, rows]);

  function handleToggle(buttonType) {
    const isLoop = buttonType === 'loop';
    if (isLoop) {
      if (autoplay && !loop) {
        toggleAutoplay();
      }
      toggleLoop();
    } else {
      if (!autoplay && loop) {
        toggleLoop();
      }
      toggleAutoplay();
    }
  }

  function playNextSong(forward = true, rowLength = 1_000_000, nextList) {
    // const songTitles = nextList.map(
    //   (item) => `${item.ARTIST} - ${item.SONGNAME}`
    // );
    // console.log(`nextlist: ${JSON.stringify(songTitles)}`);
    if (nextList.length > 0) {
      nextSong = nextList[nextList.length - 1];
      removeFromShortList();
      setPlayingSong(nextSong);
      handleSelectSong(nextSong);
      setIsAudioPlaying(true);
      return;
    }
    const currentKey = playingSong.KEY;
    const keys = rows.map((item) => item.KEY);
    const currentIndex = keys.indexOf(currentKey);
    if (currentIndex === -1) {
      setIsAudioPlaying(false);
      return null;
    }
    if (!forward && currentIndex === 0) {
      return;
    }
    if (currentIndex == rowLength - 1) {
      setIsAudioPlaying(false);
      return;
    }
    const delta = forward ? 1 : -1;
    const targetKey = keys[currentIndex + delta];
    var nextSong = null;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].KEY === targetKey) {
        nextSong = rows[i];
      }
    }
    setPlayingSong(nextSong);
    handleSelectSong(nextSong);
    setIsAudioPlaying(true);
  }

  React.useEffect(() => {
    const audio = audioRef.current;

    const handleAudioEnded = () => {
      setRows((oldRows) => {
        const newData = oldRows.map((row) => {
          if (row.KEY === playingSong.KEY) {
            const newPlayCount = row.PLAYCOUNT + 1;
            const lastPlayedTime = new Date();
            const updatedSong = {
              ...playingSong,
              PLAYCOUNT: newPlayCount,
              LAST_PLAYED: lastPlayedTime,
            };
            const newValues = {
              PREV_VALUES: {
                PLAYCOUNT: playingSong.PLAYCOUNT.toString(),
                LAST_PLAYED: playingSong.LAST_PLAYED,
              },
              NEW_VALUES: {
                PLAYCOUNT: newPlayCount.toString(),
                LAST_PLAYED: lastPlayedTime,
              },
            };
            editSong(playingSong.KEY, newValues, token);
            return updatedSong;
          }
          return row;
        });
        return newData;
      });
      console.log(`shortList: ${JSON.stringify(shortList)}`);

      if (autoplay) {
        playNextSong(true, rows.length, shortList);
      } else if (loop) {
        audio.currentTime = 0;
        audio.play();
      } else {
        // audio.currentTime = audio.duration - .1;
        audio.currentTime = 0;
        audio.pause();
      }
    };

    audio.addEventListener('ended', handleAudioEnded);

    return () => {
      audio.removeEventListener('ended', handleAudioEnded);
    };
  }, [autoplay, loop, playingSong, rows, audioRef, shortList]);

  return (
    <Wrapper>
      <SeekerSlider
        audioRef={audioRef}
        src={src}
        playingSong={playingSong}
        playNextSong={playNextSong}
        loop={loop}
        handleToggle={handleToggle}
      ></SeekerSlider>
      <audio
        src={src}
        ref={audioRef}
        // controls
        preload='metadata'
      />
    </Wrapper>
  );
}

export default React.memo(AudioPlayer);

const Wrapper = styled.div``;
