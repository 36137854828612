import React from 'react';
import * as style from './DeleteModal.module.css';

function DeleteModal({ isOpen, onClose, song, followUp }) {
  React.useEffect(() => {
    function handleEscape(event) {
      onClose();
    }
    window.addEventListener('keydown', handleEscape);
    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, []);

  function deleteSong() {
    const api_url = process.env.REACT_APP_SONG_URL + song.KEY;
    fetch(api_url, {
      method: 'DELETE',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    });
  }

  const handleConfirm = () => {
    deleteSong();
    followUp();
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className={style.modal}>
      <div className={style.modalContent}>
        <h2>Are you sure you want to delete the song?</h2>
        <div>Artist: {song.ARTIST}</div>
        <div>Name: {song.SONGNAME}</div>
        <button onClick={handleConfirm}>Confirm</button>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
}

export default DeleteModal;
