.star {
  color: var(--star-default-color);
}

.starHighlighted {
  color: var(--star-selected-color);
}

.unstar {
  color: var(--star-nonstar-outline);
}
