import React from 'react';
import * as style from './SeekerSlider.module.css';
import { getFormattedLength } from '../../utils';
import SearchBar from '../SearchBar/SearchBar';
import playButton from '../../assets/playbutton.png';
import pauseButton from '../../assets/pausebutton.png';
import backButton from '../../assets/backbutton.png';
import forwardButton from '../../assets/forwardbutton.png';
import shuffleDefault from '../../assets/shuffle-default.png';
import loopIsOn from '../../assets/loopon.png';
import loopIsOff from '../../assets/loopoff.png';
import { DataContext } from '../DataProvider';

import styled from 'styled-components';
import SliderDemo from '../SliderDemo/SliderDemo';

function SeekerSlider({
  audioRef,
  src,
  playingSong,
  playNextSong,
  loop,
  handleToggle,
}) {
  const [songDuration, setSongDuration] = React.useState(0);
  const [currentPlaybackTime, setCurrentPlaybackTime] = React.useState(0);
  const sliderRef = React.useRef(null);
  const playPauseSpanRef = React.useRef(null);
  const { isAudioPlaying, setIsAudioPlaying, handleShuffle, shortList, rows } =
    React.useContext(DataContext);

  React.useEffect(() => {
    const audio = audioRef.current;
    const handleLoadedMetadata = () => {
      const songDurationInt = Math.floor(audio.duration);
      setSongDuration(songDurationInt);
    };

    if (audio) {
      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    }

    return () => {
      if (audio) {
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  }, [src]);

  React.useEffect(() => {
    const audio = audioRef.current;
    const intervalId = setInterval(() => {
      if (audio) {
        const currentTime = Math.floor(audio.currentTime);
        setCurrentPlaybackTime(currentTime);
        sliderRef.current.value = currentTime.toString();
        const percentagePlayed = (currentTime / songDuration) * 100;
        const value = percentagePlayed;
        // TODO: uncomment
        // sliderRef.current.style.background = `linear-gradient(to right, var(--track-active-color) 0%, var(--track-active-color) ${value}%, var(--track-color) ${value}%, var(--track-color) 100%)`;
        sliderRef.current.style.background = `linear-gradient(to right, var(--track-active-color) 0%, var(--track-active-color) ${value}%, var(--track-color) ${value}%, var(--track-color) 100%)`;
        // sliderRef.current.style.setProperty('--before-width', `${percentagePlayed}%`);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [sliderRef]);

  React.useEffect(() => {
    const audio = audioRef.current;
    const playPauseSpan = playPauseSpanRef.current;
    const handlePlayPauseClick = () => {
      if (Object.keys(playingSong).length !== 0 && audio) {
        if (audio.paused) {
          audio.play();
        } else {
          audio.pause();
        }
        setIsAudioPlaying();
      }
    };
    playPauseSpan.addEventListener('click', handlePlayPauseClick);
    return () => {
      playPauseSpan.removeEventListener('click', handlePlayPauseClick);
    };
  }, [playingSong, audioRef]);

  React.useEffect(() => {
    const audio = audioRef.current;
    const seekSlider = sliderRef.current;

    const handleChange = () => {
      if (audio && seekSlider) {
        audio.currentTime = seekSlider.value;
      }
    };

    if (seekSlider) {
      seekSlider.addEventListener('change', handleChange);
    }

    return () => {
      if (seekSlider) {
        seekSlider.removeEventListener('change', handleChange);
      }
    };
  }, [audioRef, sliderRef]);

  const currentPlaybackTimeLegible = getFormattedLength(
    currentPlaybackTime,
    true
  );
  const songDurationLegible = getFormattedLength(songDuration, true);
  const timeNotInitialized = songDurationLegible === '?';
  const displayTime =
    currentPlaybackTimeLegible !== '?' ? currentPlaybackTimeLegible : '0:00';
  const sliderMax = timeNotInitialized ? '100' : songDuration;

  const artist = playingSong.ARTIST;
  const songname = playingSong.SONGNAME;
  return (
    <TableWrapper>
      <tbody>
        <tr className={style.topRow}>
          <td className={style.outerLeft}>
            <span
              className={style.button}
              onClick={() => playNextSong(false, rows.length, shortList)}
            >
              <img
                className={style.backButton}
                src={backButton}
                alt="Back Button"
              />
            </span>
            <span className={style.button} ref={playPauseSpanRef}>
              <img
                className={`${style.playButton} ${
                  !isAudioPlaying ? '' : style.invisibleImage
                } absolutePositionClass`}
                src={playButton}
                alt="Play Button"
              />
              <img
                className={`${style.playButton} ${
                  isAudioPlaying ? '' : style.invisibleImage
                } absolutePositionClass`}
                src={pauseButton}
                alt="Pause Button"
              />
            </span>
            <span
              className={style.button}
              onClick={() => playNextSong(true, rows.length, shortList)}
            >
              <img
                className={style.forwardButton}
                src={forwardButton}
                alt="Forward Button"
              />
            </span>
          </td>
          <td className={style.volumeCell}>
            <SliderDemo
              defaultValue={100}
              onValueChange={(value) => {
                const newVolume = value[0] / 100;
                audioRef.current.volume = newVolume;
                console.log(value[0]);
              }}
            />
          </td>
          <td className={style.songTimer}>
            <div
              className={`${style.iconUpperLeft} ${style.button}`}
              onClick={handleShuffle}
            >
              <img
                className={style.shuffleDefault}
                src={shuffleDefault}
                alt="Shuffle Button"
              />
            </div>
            <div>
              <span className={style.timeLowerLeft}>
                {timeNotInitialized ? (
                  <span className={style.invisiblePlaceholder}>
                    Placeholder
                  </span>
                ) : (
                  displayTime
                )}
              </span>
            </div>
          </td>
          <td className={style.middleCell}>
            <div className={style.playerUI}>
              <div className={style.infoAndTimes}>
                <div>
                  <p className={style.songName}>
                    {songname || (
                      <span className={style.invisiblePlaceholder}>
                        Placeholder
                      </span>
                    )}
                  </p>
                  <p className={style.artist}>
                    {artist || (
                      <span className={style.invisiblePlaceholder}>
                        Placeholder
                      </span>
                    )}
                  </p>
                </div>
              </div>
              <input
                type="range"
                max={sliderMax}
                step="1"
                ref={sliderRef}
                className={style.slider}
              />
            </div>
          </td>

          <td className={style.songTimer}>
            <div
              className={`${style.iconUpperRight} ${style.button}`}
              onClick={() => handleToggle('loop')}
            >
              <img
                className={`${style.loopButton} ${
                  !loop ? '' : style.invisibleImage
                } absolutePositionClass`}
                src={loopIsOff}
                alt="Loop Is Off"
              />
              <img
                className={`${style.loopOnButton} ${
                  loop ? '' : style.invisibleImage
                } absolutePositionClass`}
                src={loopIsOn}
                alt="Loop Is On"
              />
            </div>
            <div>
              <span className={style.timeLowerRight}>
                {timeNotInitialized ? (
                  <span className={style.invisiblePlaceholder}>
                    Placeholder
                  </span>
                ) : (
                  songDurationLegible
                )}
              </span>
            </div>
          </td>
          <td className={style.outerRight}>
            <SearchBar />
          </td>
        </tr>
      </tbody>
    </TableWrapper>
  );
}

const TableWrapper = styled.table`
  z-index: 1000;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: 'Lato', sans-serif;
  /* font-size: 12px; */
  width: 100%;
  font-size: 10px;
  font-weight: 400;
  position: fixed;
  top: 0px;
`;

export default SeekerSlider;
