import React from 'react';

const ENDPOINT = process.env.REACT_APP_SONGS_INDEX_URL;

import { orderListElems, getFormattedLength } from '../utils';

function useIndexFetcher(token) {
  function processJson(json) {
    const result = [];
    json.items.forEach((item) => {
      const newItem = {
        ...item,
        STARS: parseInt(item.STARS),
        PLAYCOUNT: parseInt(item.PLAYCOUNT),
        LENGTH: getFormattedLength(parseInt(item.LENGTH)),
        FILESIZE: parseInt(item.FILESIZE),
        RANDOM: Math.random(),
      };
      result.push(newItem);
    });
    const orderedData = result.sort((a, b) => orderListElems(a, b, false, 0));
    return orderedData;
  }

  async function getData() {
    const request = new Request(ENDPOINT, {
      method: 'GET',
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY,
        Authorization: token,
      },
      timeout: 100000,
    });
    const response = await fetch(request);
    const json = await response.json();
    const processedJson = processJson(json);
    if (!data) {
      setData((oldData) => processedJson);
    }
  }

  const [data, setData] = React.useState(null);
  if (token !== null) {
    getData();
  }

  return data;
}

export default useIndexFetcher;
