.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-items: flex-start;
  font-family: 'Lato', sans-serif;
}

.modalContent {
  background-color: #fefefe;
  /* margin: auto; */
  /* margin-top: 10px; */
  margin-top: 10%;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.modalContent h2 {
  margin-bottom: 20px;
}

.modalContent div {
  margin-bottom: 10px;
}

.modalContent input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid #888;
}

.modalContent button {
  background-color: #1f5cab;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

.modalContent button:hover {
  opacity: 1;
}
