.searchBar {
  background-color: #fff;
  border-radius: 4px;
  height: 22px;
  width: 40%;
  margin-left: 55% !important;
  border: 1.5px solid #aaa !important;
  background-image: url('../../assets/magnifying-glass.png');
  background-repeat: no-repeat;
  background-position: left;
  padding-left: 30px;
}

.searchBar:focus {
  border: 2px solid #8091d9 !important;
  /* color: #8091d9; */
  color: black;
  border-color: #8091d9 !important;
  outline-color: #8091d9;
  box-shadow: 0 0 20px #8091d9;
  background-color: white;
}
