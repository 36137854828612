import React from 'react';
import Table from '../Table';
import * as style from './App.module.css';

import DataProvider from '../DataProvider';
import UserProvider from '../UserProvider';
import Footer from '../Footer/Footer';
import AudioPlayer from '../AudioPlayer';
import ContextMenuDemo from '../ContextMenuDemo/ContextMenuDemo';
import Playlists from '../Playlists';

import styled from 'styled-components';

function App() {
  return (
    <UserProvider>
      <DataProvider>
        <div className={style.sticky}>
          <AudioPlayer />
          <ContextMenuDemo>
            <Wrapper>
              <PlaylistsWrapper>
                <Playlists />
              </PlaylistsWrapper>
              <LibraryWrapper>
                <Table />
              </LibraryWrapper>
            </Wrapper>
          </ContextMenuDemo>
          <Footer />
        </div>
      </DataProvider>
    </UserProvider>
  );
}

export default App;

const Wrapper = styled.div`
  width: 1200px;
  border-spacing: 0px;
`;

const PlaylistsWrapper = styled.div`
  position: absolute;
  top: 40.5px;
  z-index: 100;
  width: var(--playlists-width);
  padding: 0px;
  line-height: 1.4rem;
`;

const LibraryWrapper = styled.div`
  position: absolute;
  top: 41px;
  left: 198px;
  padding: 0px;
`;

// const WrapperDiv
