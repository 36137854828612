import React from 'react';

import * as style from './TableRow.module.css';
import Stars from '../Stars';
import { DataContext } from '../DataProvider';
import { UserContext } from '../UserProvider';
import useToggle from '../../hooks/use-toggle';
import ReadOnlyTableRow from './ReadOnlyTableRow';
import EditableTableRow from './EditableTableRow';
import speakerMutedUnselected from '../../assets/speaker-muted-unselected.png';
import speakerMutedSelected from '../../assets/speaker-muted-selected.png';
import speakerAudibleUnselected from '../../assets/speaker-audible-unselected.png';
import speakerAudibleSelected from '../../assets/speaker-audible-selected.png';
import { editSong } from '../../utils';

import * as trstyle from './TableRowStyles';

import styled from 'styled-components';

function TableRow({ item }) {
  // console.log('rerendering tableRow')
  const [lastClickTime, setLastClickTime] = React.useState(0);
  const doubleClickThreshold = 1000;
  const {
    selectedSong,
    handleSelectSong,
    setRows,
    playingSong,
    setPlayingSong,
    prevSelectedSong,
    isAudioPlaying,
    setIsAudioPlaying,
    setIsLeftBarFocused,
  } = React.useContext(DataContext);
  const { token } = React.useContext(UserContext);
  const [isEditingMode, toggleEditingMode] = useToggle(false);
  const [editableField, setEditableField] = React.useState('');

  const isSelected = selectedSong && item.KEY === selectedSong.KEY;
  const isPlaying = playingSong && item.KEY === playingSong.KEY;

  const handleFieldClick = (field) => {
    // console.log(`in callback with field = ${field}`)
    setEditableField(field);
  };

  // Potentially turn into useEffect ?!?
  const handleClick = (event) => {
    const isConsecutiveClick =
      prevSelectedSong.current && prevSelectedSong.current.KEY === item.KEY;
    const currentTime = event.timeStamp;
    const timeDiff = currentTime - lastClickTime;

    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
      handleSelectSong(item);
      setPlayingSong(item);
    } else if (lastClickTime === 0) {
      // console.log('first click!')
      setLastClickTime(currentTime);
      handleSelectSong(item);
    } else if (timeDiff < 0) {
      // console.log('negative timeDiff')
      setLastClickTime(currentTime);
      handleSelectSong(item);
    } else if (timeDiff < doubleClickThreshold) {
      // console.log('double click!')
      setLastClickTime(currentTime);
      handleSelectSong(item);
      setPlayingSong(item);
      setIsAudioPlaying(true);
    } else if (timeDiff >= doubleClickThreshold && isConsecutiveClick) {
      // console.log('slow click, field edit potentially intended')
      console.log(
        !isEditingMode
          ? `editing enabled on field ${editableField}!`
          : 'editing disabled'
      );
      setLastClickTime(currentTime + 1000);
      handleSelectSong(item);
      toggleEditingMode();
    } else {
      setLastClickTime(currentTime);
      // console.log('slow click, different item, no field edit intended')
      handleSelectSong(item);
    }
    setIsLeftBarFocused(false);
  };

  // console.log('selectable table row')
  const setStars = (rating) => {
    setRows((oldRows) => {
      const newData = oldRows.map((song) => {
        if (song.KEY === item.KEY) {
          return { ...song, STARS: rating };
        }
        return song;
      });
      return newData;
    });
    const newValues = {
      NEW_VALUES: {
        STARS: rating.toString(),
      },
    };
    editSong(item.KEY, newValues, token);
  };

  const songTableRowStyle = isSelected
    ? `${style.selectedRow} ${style.songTableRow}`
    : style.songTableRow;

  return (
    <StyledTableRowWrapper
      // className={songTableRowStyle}
      isSelected={isSelected}
      onClick={(event) => handleClick(event)}
      onContextMenu={(event) => handleClick(event)}
    >
      <trstyle.PlayIconTD key='playsong'>
        <div className='relativePositionClass'>
          <span className={style.invisiblePlaceholder}>PPP</span>
          <img
            className={`${style.speakerIcon} ${
              isPlaying && isSelected && isAudioPlaying
                ? ''
                : style.invisibleImage
            } absolutePositionClass`}
            src={speakerAudibleSelected}
            alt='Speaker Icon'
          />
          <img
            className={`${style.speakerIcon} ${
              isPlaying && isSelected && !isAudioPlaying
                ? ''
                : style.invisibleImage
            } absolutePositionClass`}
            src={speakerMutedSelected}
            alt='Speaker Icon'
          />
          <img
            className={`${style.speakerOnUnselectedIcon} ${
              isPlaying && !isSelected && isAudioPlaying
                ? ''
                : style.invisibleImage
            } absolutePositionClass`}
            src={speakerAudibleUnselected}
            alt='Speaker Icon'
          />
          <img
            className={`${style.speakerIcon} ${
              isPlaying && !isSelected && !isAudioPlaying
                ? ''
                : style.invisibleImage
            } absolutePositionClass`}
            src={speakerMutedUnselected}
            alt='Speaker Icon'
          />
        </div>
      </trstyle.PlayIconTD>
      {!isSelected && (
        <ReadOnlyTableRow item={item} handleFieldClick={handleFieldClick} />
      )}
      {isSelected && !isEditingMode && (
        <ReadOnlyTableRow item={item} handleFieldClick={handleFieldClick} />
      )}
      {isSelected && isEditingMode && (
        <EditableTableRow
          item={item}
          toggleOff={toggleEditingMode}
          handleFieldClick={handleFieldClick}
          editableField={editableField}
        />
      )}
      <StarsTD className={style.starsTD} onClick={(e) => e.stopPropagation()}>
        <Stars
          numStars={item.STARS}
          setStars={setStars}
          isSelected={isSelected}
        />
      </StarsTD>
      <FinalTD>
        <FinalTDContent></FinalTDContent>
      </FinalTD>
    </StyledTableRowWrapper>
  );
}

const StarsTD = styled.td`
  padding-top: 4px;
  padding-bottom: 1px;
  width: var(--stars-td-width);
`;

const FinalTD = styled.td`
  min-width: clamp(10px, 100%, 1000px);
  /* max-width: 100%; */
`;

const StyledTableRowWrapper = ({
  children,
  isSelected,
  onClick,
  onContextMenu,
}) => {
  const { isLeftBarFocused } = React.useContext(DataContext);
  if (isSelected) {
    console.log('I was selected!');
  }
  return (
    <TableRowWrapper
      isLeftBarFocused={isLeftBarFocused}
      isSelected={isSelected}
      onClick={onClick}
      onContextMenu={onContextMenu}
    >
      {children}
    </TableRowWrapper>
  );
};

const TableRowWrapper = styled.tr`
  &:nth-child(even) {
    background-color: ${(p) =>
      p.isSelected
        ? !p.isLeftBarFocused
          ? 'var(--selection-default-color)'
          : 'var(--passive-playlist-select-color)'
        : 'var(--songs-table-alternating-row-color)'};
  }
  cursor: pointer;
  color: ${(p) =>
    p.isSelected ? (!p.isLeftBarFocused ? 'white' : 'black') : 'black'};
  background-color: ${(p) =>
    p.isSelected
      ? !p.isLeftBarFocused
        ? 'var(--selection-default-color)'
        : 'var(--passive-playlist-select-color)'
      : ''};
`;

const FinalTDContent = styled.div`
  /* backgroundx  -color: red; */
  min-width: calc(100vw - 1184px);
`;

export default React.memo(TableRow);
