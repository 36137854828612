import React from 'react';

import styled from 'styled-components';
import { DataContext } from '../DataProvider';

import { StyledPlaylist } from '../SelectedStyling';

function Playlists() {
  const { selectedPlaylist, handleSelectPlaylist, setIsLeftBarFocused } =
    React.useContext(DataContext);

  const handleClick = (event) => {
    console.log('clicked');
    // const isConsecutiveClick =
    //   prevSelectedSong.current && prevSelectedSong.current.KEY === item.KEY;
    // const currentTime = event.timeStamp;
    // const timeDiff = currentTime - lastClickTime;

    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    handleSelectPlaylist(event);
    setIsLeftBarFocused(true);
    // if (isMobile) {
    //   handleSelectSong(item);
    //   setPlayingSong(item);
    // } else if (lastClickTime === 0) {
    //   // console.log('first click!')
    //   setLastClickTime(currentTime);
    //   handleSelectSong(item);
    // } else if (timeDiff < 0) {
    //   // console.log('negative timeDiff')
    //   setLastClickTime(currentTime);
    //   handleSelectSong(item);
    // } else if (timeDiff < doubleClickThreshold) {
    //   // console.log('double click!')
    //   setLastClickTime(currentTime);
    //   handleSelectSong(item);
    //   setPlayingSong(item);
    //   setIsAudioPlaying(true);
    // } else if (timeDiff >= doubleClickThreshold && isConsecutiveClick) {
    //   // console.log('slow click, field edit potentially intended')
    //   console.log(
    //     !isEditingMode
    //       ? `editing enabled on field ${editableField}!`
    //       : 'editing disabled'
    //   );
    //   setLastClickTime(currentTime + 1000);
    //   handleSelectSong(item);
    //   toggleEditingMode();
    // } else {
    //   setLastClickTime(currentTime);
    //   // console.log('slow click, different item, no field edit intended')
    //   handleSelectSong(item);
    // }
  };

  return (
    <Wrapper>
      <PlaylistCategory>Library</PlaylistCategory>
      <ListWrapper>
        <StyledPlaylist
          isSelected={selectedPlaylist === 'songs'}
          onClick={() => handleClick('songs')}
        >
          Songs
        </StyledPlaylist>
      </ListWrapper>
      <PlaylistCategory>Music Playlists</PlaylistCategory>
      <ListWrapper>
        <StyledPlaylist
          isSelected={selectedPlaylist === 'chill-electronica'}
          onClick={() => handleClick('chill-electronica')}
        >
          Chill Electronica
        </StyledPlaylist>
        <StyledPlaylist
          isSelected={selectedPlaylist === 'grime'}
          onClick={() => handleClick('grime')}
        >
          Grime
        </StyledPlaylist>
        <StyledPlaylist
          isSelected={selectedPlaylist === 'hip-hop'}
          onClick={() => handleClick('hip-hop')}
        >
          Hip Hop
        </StyledPlaylist>
      </ListWrapper>
    </Wrapper>
  );
}

export default Playlists;

const Wrapper = styled.div`
  z-index: -100;
  position: fixed;
  top: 40.5px;
  /* margin-top: 0px; */
  font-family: 'Lato', sans-serif;
  border-right: var(--audio-player-border);
  width: var(--playlists-width);
  height: 100vh;
  text-align: left;
  padding: none;
  padding-top: 5px;
`;

const ListWrapper = styled.ul`
  position: sticky;
  top: 0;
  list-style-type: none;
  padding: none;
  padding-inline-start: 0px;
  cursor: pointer;
`;

// const Playlist = styled.li`
//   width: var(--playlists-width);
//   font-size: 13px;
//   padding: none;
//   padding-left: 16px;
//   color: ${(p) => (p.isSelected ? 'black' : 'black')};
//   background-color: ${(p) =>
//     p.isSelected ? 'var(--passive-playlist-select-color);' : 'hsl(0 0 0 / 0)'};
// `;

const PlaylistCategory = styled.p`
  font-size: 10px;
  font-weight: 900;
  padding-left: 8px;
  cursor: default;
`;
