import React from 'react';

import styled from 'styled-components';

import { DataContext } from '../DataProvider';

export const StyledPlaylist = ({ children, isSelected, onClick }) => {
  const { isLeftBarFocused } = React.useContext(DataContext);
  return (
    <Playlist
      isSelected={isSelected}
      isLeftBarFocused={isLeftBarFocused}
      onClick={onClick}
    >
      {children}
    </Playlist>
  );
};

// export const StyledTableRow = ({ children, isSelected, onClick }) => {
//   const { isLeftBarFocused } = React.useContext(DataContext);
//   return (
//     <Playlist
//       isSelected={isSelected}
//       isLeftBarFocused={isLeftBarFocused}
//       onClick={onClick}
//     >
//       {children}
//     </Playlist>
//   );
// };

const Playlist = styled.li`
  width: var(--playlists-width);
  font-size: 13px;
  padding: none;
  padding-left: 16px;
  color: ${(p) =>
    p.isSelected ? (p.isLeftBarFocused ? 'white' : 'black') : 'black'};
  background-color: ${(p) =>
    p.isSelected
      ? p.isLeftBarFocused
        ? 'var(--selection-default-color)'
        : 'var(--passive-playlist-select-color);'
      : 'hsl(0 0 0 / 0)'};
  border-right: var(--audio-player-border);
`;
