.stickyFooter {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-image: linear-gradient(
    var(--footer-gradient-top),
    var(--footer-gradient-bottom)
  );
  text-align: center;
  font-family: 'Lato', sans-serif !important;
  font-size: 12px;
  border: 1px solid #aaa;
  padding: 2px;
}
