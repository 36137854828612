import React from 'react';
import * as style from './Stars.module.css';
import { range } from '../../utils';

function getStars(numStars, setStars, isSelected) {
  const parsedRating = parseInt(numStars);
  const starClass = isSelected ? style.starHighlighted : style.star;
  const unstarClass = isSelected ? style.starHighlighted : style.unstar;

  const resultStars = (
    <>
      <span style={{ opacity: '0' }} onClick={() => setStars(0)}>
        {'★'}
      </span>
      {range(1, 6).map((index) => {
        const starIsStarred = parsedRating >= index;
        return (
          <span
            key={index}
            className={starIsStarred ? starClass : unstarClass}
            onClick={() => setStars(index)}
          >
            {starIsStarred ? '★' : '☆'}
          </span>
        );
      })}
    </>
  );
  return resultStars;
}

function Stars({ numStars, itemKey, setStars, isSelected }) {
  const stars = getStars(numStars, setStars, isSelected);
  return <>{stars}</>;
}

export default React.memo(Stars);
