import React from 'react';
import * as style from './Footer.module.css';
import { DataContext } from '../DataProvider';

import styled from 'styled-components';

import {
  orderListElems,
  getFormattedLibrarySize,
  convertToSeconds,
  convertSecondsToLegibleTime,
} from '../../utils';

function Footer() {
  const { rows } = React.useContext(DataContext);

  if (rows) {
    const numSongs = rows.length;
    const result = rows.map((item) => item.FILESIZE).reduce((a, b) => a + b, 0);
    const numGigabytes = getFormattedLibrarySize(result);
    const totalLibrarySize = numGigabytes;
    const timeResult = rows
      .map((item) => convertToSeconds(item.LENGTH))
      .reduce((a, b) => a + b, 0);
    const totalLibraryLength = convertSecondsToLegibleTime(timeResult);
    return (
      <Wrapper className={style.stickyFooter}>
        {numSongs} songs, {totalLibraryLength}, {totalLibrarySize}
      </Wrapper>
    );
  }

  return <Wrapper className={style.stickyFooter}>Loading songs...</Wrapper>;
}

export default Footer;

const Wrapper = styled.div`
  z-index: 1000;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-image: linear-gradient(
    var(--footer-gradient-top),
    var(--footer-gradient-bottom)
  );
  text-align: center;
  font-family: 'Lato', sans-serif !important;
  font-size: 12px;
  border: 1px solid #aaa;
  padding: 2px;
`;
