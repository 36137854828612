import React from 'react';

import SongSize from '../SongSize';
import SongDate from '../SongDate';
import { DataContext } from '../DataProvider';
import { UserContext } from '../UserProvider';

import { editSong, convertToSeconds } from '../../utils';

import {
  ArtistTD,
  SongNameTD,
  LengthTD,
  SizeTD,
  PlaysTD,
  InputField,
  LastPlayedTD,
  DateAddedTD,
} from './TableRowStyles';

function EditableTableRow({
  item,
  toggleOff,
  handleFieldClick,
  editableField,
}) {
  const [values, setValues] = React.useState({
    ARTIST: item.ARTIST,
    SONGNAME: item.SONGNAME,
    LENGTH: item.LENGTH,
    PLAYCOUNT: item.PLAYCOUNT,
  });
  const { token } = React.useContext(UserContext);

  const inputRef = React.useRef(null);
  const artistRef = React.useRef(null);
  const songNameRef = React.useRef(null);
  const lengthRef = React.useRef(null);
  const playCountRef = React.useRef(null);

  React.useEffect(() => {
    if (editableField === 'ARTIST' && artistRef.current) {
      artistRef.current.focus();
      artistRef.current.select();
    } else if (editableField === 'SONGNAME' && songNameRef.current) {
      songNameRef.current.focus();
      songNameRef.current.select();
    } else if (editableField === 'LENGTH' && lengthRef.current) {
      lengthRef.current.focus();
      lengthRef.current.select();
    } else if (editableField === 'PLAYCOUNT' && playCountRef.current) {
      playCountRef.current.focus();
      playCountRef.current.select();
    }
  }, [editableField]);

  const { setRows, setIsEditing } = React.useContext(DataContext);

  const handleChange = (e, field) => {
    setValues({ ...values, [field]: e.target.value });
  };

  function swapTimeOrLeaveUnchanged(field, value) {
    if (field === 'LENGTH') {
      return convertToSeconds(value).toString();
    } else if (field === 'PLAYCOUNT') {
      return value.toString();
    }
    return value;
  }

  const handleBlur = (field) => {
    setIsEditing();
    toggleOff();
    if (!values[field]) {
      return;
    }
    const newValue = swapTimeOrLeaveUnchanged(field, values[field]);
    setRows((oldRows) => {
      const newData = oldRows.map((row) => {
        if (row.KEY === item.KEY) {
          return { ...row, [field]: values[field] };
        }
        return row;
      });
      return newData;
    });
    const newValues = {
      NEW_VALUES: {
        [field]: newValue,
      },
    };
    editSong(item.KEY, newValues, token);
  };

  return (
    <>
      <ArtistTD key='artist' onClick={(e) => handleFieldClick('ARTIST')}>
        {editableField === 'ARTIST' ? (
          <InputField
            type='text'
            value={values.ARTIST}
            ref={artistRef}
            onChange={(e) => handleChange(e, 'ARTIST')}
            onBlur={() => handleBlur('ARTIST')}
            onFocus={setIsEditing}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleBlur('ARTIST');
              }
            }}
            autoFocus
          />
        ) : (
          item.ARTIST
        )}
      </ArtistTD>
      <SongNameTD key='songname' onClick={(e) => handleFieldClick('SONGNAME')}>
        {editableField === 'SONGNAME' ? (
          <InputField
            type='text'
            value={values.SONGNAME}
            onChange={(e) => handleChange(e, 'SONGNAME')}
            onBlur={() => handleBlur('SONGNAME')}
            onFocus={setIsEditing}
            onClick={(e) => {
              e.stopPropagation();
            }}
            ref={songNameRef}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleBlur('SONGNAME');
              }
            }}
            autoFocus
          />
        ) : (
          item.SONGNAME
        )}
      </SongNameTD>
      <LengthTD key='length' onClick={(e) => handleFieldClick('LENGTH')}>
        {editableField === 'LENGTH' ? (
          <InputField
            maxWidth='30px'
            type='text'
            value={values.LENGTH}
            ref={lengthRef}
            onChange={(e) => handleChange(e, 'LENGTH')}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onBlur={() => handleBlur('LENGTH')}
            onFocus={setIsEditing}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleBlur('LENGTH');
              }
            }}
            autoFocus
          />
        ) : (
          item.LENGTH
        )}
      </LengthTD>

      <SizeTD key='size'>
        <SongSize bytes={item.FILESIZE} />
      </SizeTD>

      <PlaysTD key='plays' onClick={(e) => handleFieldClick('PLAYCOUNT')}>
        {editableField === 'PLAYCOUNT' ? (
          <InputField
            maxWidth='30px'
            type='text'
            value={values.PLAYCOUNT}
            ref={playCountRef}
            onChange={(e) => handleChange(e, 'PLAYCOUNT')}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onBlur={() => handleBlur('PLAYCOUNT')}
            onFocus={setIsEditing}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleBlur('PLAYCOUNT');
              }
            }}
            autoFocus
          />
        ) : (
          item.PLAYCOUNT
        )}
      </PlaysTD>

      <LastPlayedTD key='lastplayed'>
        {<SongDate date={item.LAST_PLAYED} />}
      </LastPlayedTD>
      <DateAddedTD key='dateadded'>
        {<SongDate date={item.DATE_ADDED} />}
      </DateAddedTD>
    </>
  );
}

export default EditableTableRow;
