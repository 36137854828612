const SONG_ENDPOINT = process.env.REACT_APP_SONG_URL;

export const sample = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

export const range = (start, end, step = 1) => {
  let output = [];
  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};

export const getFormattedLibrarySize = (totalBytes) => {
  const sizeInGB = (parseInt(totalBytes) / 1073741824).toFixed(2);
  if (sizeInGB < 1) {
    return (parseInt(totalBytes) / 1048576).toFixed(1).toString() + 'MB';
  } else {
    return sizeInGB.toString() + 'GB';
  }
};

export const getFormattedLength = (length) => {
  if (!length) {
    return '?';
  }
  const hours = Math.floor(length / 3600);
  const minutes = Math.floor((length % 3600) / 60);
  const seconds = Math.floor(length % 60)
    .toString()
    .padStart(2, '0');

  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds}`;
  } else {
    return `${minutes}:${seconds}`;
  }
};

export const convertToSeconds = (timeString) => {
  const timeParts = timeString.split(':').map(Number);
  let seconds = 0;

  if (timeParts.length === 3) {
    // If the format is HH:MM:SS
    seconds += timeParts[0] * 3600;
    seconds += timeParts[1] * 60;
    seconds += timeParts[2];
  } else if (timeParts.length === 2) {
    // If the format is MM:SS
    seconds += timeParts[0] * 60;
    seconds += timeParts[1];
  }
  return seconds;
};

export const convertSecondsToLegibleTime = (seconds) => {
  const days = Math.floor(seconds / (24 * 60 * 60));
  const hours = Math.floor((seconds % (24 * 60 * 60)) / 3600);
  const minutes = Math.ceil(((seconds % (24 * 60 * 60)) % 3600) / 60);

  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''}`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''}, ${minutes} minute${
      minutes > 1 ? 's' : ''
    }`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  } else {
    return `${seconds} second${seconds > 1 ? 's' : ''}`;
  }
};

const getAttributeOrder = (selectedColumn) => {
  if (selectedColumn === 0) {
    return ['ARTIST', 'SONGNAME', 'RANDOM'];
  } else if (selectedColumn === 1) {
    return ['SONGNAME', 'ARTIST', 'RANDOM'];
  } else if (selectedColumn === 2) {
    return ['LENGTH', 'ARTIST', 'SONGNAME', 'RANDOM'];
  } else if (selectedColumn === 3) {
    return ['FILESIZE', 'ARTIST', 'SONGNAME', 'RANDOM'];
  } else if (selectedColumn === 4) {
    return ['PLAYCOUNT', 'ARTIST', 'SONGNAME', 'RANDOM'];
  } else if (selectedColumn === 5) {
    return ['LAST_PLAYED', 'ARTIST', 'SONGNAME', 'RANDOM'];
  } else if (selectedColumn === 6) {
    return ['DATE_ADDED', 'ARTIST', 'SONGNAME', 'RANDOM'];
  } else if (selectedColumn === 7) {
    return ['STARS', 'ARTIST', 'SONGNAME', 'RANDOM'];
  } else if (selectedColumn === 100) {
    return ['RANDOM'];
  }
};

export const orderListElems = (a, b, reverseSort, selectedColumn) => {
  const order = getAttributeOrder(selectedColumn);
  for (const attr of order) {
    if (!a[attr] && b[attr]) {
      return 1;
    }
    if (a[attr] && !b[attr]) {
      return -1;
    }
    if (!a[attr] && !b[attr]) {
      continue;
    }
    if (a[attr] === '' && b[attr] !== '') {
      return 1;
    }
    if (a[attr] !== '' && b[attr] === '') {
      return -1;
    }
    if (a[attr] === b[attr]) {
      continue;
    }
    var aValue = a[attr];
    var bValue = b[attr];
    if (attr === 'PLAYCOUNT') {
      aValue = parseInt(a[attr]) * -1 || 0;
      bValue = parseInt(b[attr]) * -1 || 0;
    }
    if (['LENGTH', 'FILESIZE', 'STARS'].includes(attr)) {
      if (attr === 'LENGTH') {
        aValue = convertToSeconds(aValue) * -1;
        bValue = convertToSeconds(bValue) * -1;
      } else {
        aValue = parseInt(a[attr]) * -1;
        bValue = parseInt(b[attr]) * -1;
      }
    }

    if (['LAST_PLAYED', 'DATE_ADDED'].includes(attr) && attr === order[0]) {
      var aValue = a[attr];
      var bValue = b[attr];
      var temp = aValue;
      aValue = bValue;
      bValue = temp;
    }
    if (aValue < bValue) {
      if (reverseSort && attr === order[0]) {
        return 1;
      }
      return -1;
    }
    if (aValue > bValue) {
      if (reverseSort && attr === order[0]) {
        return -1;
      }
      return 1;
    }
  }
  return -1;
};

export const editSong = (itemKey, newValues, token) => {
  const api_url = `${SONG_ENDPOINT}${itemKey}`;
  fetch(api_url, {
    method: 'PUT',
    headers: {
      'x-api-key': process.env.REACT_APP_API_KEY,
      Authorization: token,
    },
    body: JSON.stringify(newValues),
  });
};
