import React from 'react';

function SongSize({ bytes }) {
  const getFormattedSize = (size) => {
    return (parseInt(size) / 1048576).toFixed(1).toString() + 'MB';
  };
  return <>{getFormattedSize(bytes)}</>;
}

export default React.memo(SongSize);
